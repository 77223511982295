<template>
	<div>
		<div class="text-right">
			<b-button
				v-b-modal.query-modal
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				size="sm"
				variant="success"
				@click="query_modal_show(null)"
			>
				<feather-icon class="mr-50" icon="PlusIcon" />
				新增查询
			</b-button>
			<b-button
				v-show="!!panel_modal.query_id"
				v-b-modal.query-modal
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				class="ml-1"
				size="sm"
				variant="warning"
				@click="query_modal_show(panel_modal.query_id)"
			>
				<feather-icon class="mr-50" icon="EditIcon" />
				编辑查询
			</b-button>
		</div>
		<query-modal></query-modal>
		<b-form-group label="历史查询" label-for="panel_query">
			<v-select
				v-model="panel_modal.query_id"
				:clearable="false"
				:options="query_list"
				:reduce="(text) => text.value"
				class="rounded"
				label="text"
				placeholder="请选择查询数据"
				@input="fetchQueryData(panel_modal.query_id)"
			/>
		</b-form-group>

		<div class="d-flex justify-content-between align-items-center my-1">
			<small>
				<span class="mr-1">
					查询时间
					<span class="text-primary">{{
						query_data.sql_explain.sql_time_end
					}}</span>
				</span>
				<span class="mr-1">
					耗时
					<span class="text-primary">{{
						query_data.sql_explain.sql_time_used
					}}</span>
					s
				</span>
				<span v-if="query_data.total < 5000" class="mr-1">
					共查询
					<span class="text-primary">{{ query_data.total }}</span>
					条数据
				</span>
				<span v-else>
					为保证页面相应速度，建议查询结果数量少于5000条，超出部分结果不会显示
				</span>
			</small>
			<div class="d-flex">
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					:disabled="loading"
					class="btn-icon mr-1"
					size="sm"
					variant="warning"
					@click="fetchQuery(panel_modal.query_id)"
				>
					<b-spinner v-if="loading" small />
					<feather-icon v-else icon="RefreshCcwIcon" />
				</b-button>
				<download-excel
					:key="'panel-data-download' + panel_modal.panel_id"
					:data="query_data.temp_data"
					:fields="exportExcelHeaders(query_data.fields)"
					name="下载查询数据.xls"
					type="csv"
					worksheet="My Worksheet"
				>
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						class="btn-icon"
						size="sm"
						variant="success"
					>
						<feather-icon icon="DownloadIcon" />
					</b-button>
				</download-excel>
			</div>
		</div>
		<b-overlay
			:blur="'3rem'"
			:show="loading"
			:variant="'transparent'"
			opacity="0.9"
			rounded="sm"
			spinner-small
			spinner-type="grow"
			spinner-variant="primary"
		>
			<b-table
				:key="'panel-data-table' + panel_modal.panel_id"
				:empty-text="$t('No Data')"
				:fields="query_data.fields"
				:items="query_data.temp_data.slice(0, 5)"
				class="m-0"
				no-border-collapse
				responsive
				small
				sticky-header
				style="height: 12rem"
			>
				<template v-slot:head(name)="data">
					<span>{{ data.text }}</span>
				</template>
			</b-table>
		</b-overlay>
	</div>
</template>

<script>
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import Ripple from "vue-ripple-directive";
import QueryModal from "../../QueryModal/QueryModal.vue";
import {
	computed,
	onMounted,
	reactive,
	toRefs,
	watch,
} from "@vue/composition-api";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";

import { query_temp_data } from "@/views/panel-group/defaultSettings";
import { routerParams } from "@/libs/utils/routerParams";

export default {
	name: "PanelModalData",
	directives: {
		Ripple,
	},
	components: {
		QueryModal,
		vSelect,
		downloadExcel: JsonExcel,
	},
	props: ["item"],
	setup(props) {
		const { item } = props;
		// ------------------------------------------------
		// 引入响应数据
		// ------------------------------------------------
		const event = reactive({
			query_list: [],
			panel_modal: item,
			loading: false,
			project_id: computed(() => {
				return routerParams("project_id");
			}),
			query_data: computed(() => {
				const query_key = "query_" + event.panel_modal.query_id;
				let data =
					store.state["panel-group"]["query-data-list"][query_key];
				return data ? data : query_temp_data;
			}),
		});
		/**
		 * 监听查询列表更新
		 */
		watch(
			() => store.state["panel-group"]["query-list"],
			(value) => {
				if (!!value) {
					event.query_list = value;
					//查询列表更新后，更新查询临时数据
					if (event.panel_modal.query_id) {
						fetchQuery(event.panel_modal.query_id);
					}
				}
			}
		);
		/**
		 * 监听query_id变化时，获取新的查询结果
		 */
		watch(
			() => event.panel_modal.query_id,
			(oldValue, value) => {
				if (!!value && oldValue !== value) {
					showToast(null, "提示", "已切换查询，正在获取查询历史数据");
					fetchQueryData(value);
				}
			}
		);
		/**
		 * 显示query弹窗,并更新弹窗数据
		 * @param query_id
		 */
		const query_modal_show = (query_id) => {
			let query = store.state["panel-group"]["query-modal"];
			if (query_id) {
				for (let query_item of event.query_list) {
					if (query_item.query_id === query_id) {
						query_item.query_map =
							query_item.query_map instanceof Array
								? query_item.query_map
								: [];
						query = query_item;
						break;
					}
				}
			} else {
				query.query_id = null;
			}
			store.commit("panel-group/UPDATE_QUERY_MODAL", query);
		};
		/**
		 * 获取查询列表
		 */
		const fetchQueryList = () => {
			store
				.dispatch("panel-group/fetchQueryList", {
					project_id: event.project_id,
				})
				.then((response) => {
					let { list } = response.data;
					formatQueryList(list);
					showToast(null, "成功", "查询列表获取成功");
				})
				.catch((error) => {
					showToast(error);
				});
		};
		/**
		 * 整理query_list数据格式，用于select
		 * @param list
		 */
		const formatQueryList = (list) => {
			list.map((item) => {
				item.value = item.query_id;
				item.text = item.query_name;
			});
			store.commit("panel-group/UPDATE_QUERY_LIST", list);
		};

		/**
		 * 获取数据源列表
		 * @param query_id
		 */
		const fetchQuery = (query_id) => {
			event.loading = true;
			store
				.dispatch("panel-group/fetchQuery", {
					query_id: query_id,
					project_id: event.project_id,
				})
				.then((response) => {
					event.loading = false;
					const data = {
						...response.data.temp_data,
						loading: false,
					};
					update_query_data_list(query_id, data);
					showToast(null, "成功", "查询详情获取成功");
				})
				.catch((error) => {
					event.loading = false;
					showToast(error);
				});
		};
		/**
		 * 查询历史临时数据
		 * @param query_id
		 * @returns {Promise<void>}
		 */
		const fetchQueryData = (query_id) => {
			event.loading = true;
			store
				.dispatch("panel-group/fetchQueryData", {
					query_id: query_id,
					project_id: event.project_id,
				})
				.then((response) => {
					event.loading = false;
					const data = {
						...response.data,
						loading: false,
					};
					update_query_data_list(query_id, data);
					showToast(null, "成功", "查询历史结果数据获取成功");
				})
				.catch((error) => {
					event.loading = false;
					showToast(error);
				});
		};

		//合并store中原有数据和新数据，并更新store
		const update_query_data_list = (query_id, data) => {
			let query_data = {};
			query_data["query_" + query_id] = data;
			let query_data_list = JSON.parse(
				JSON.stringify(store.state["panel-group"]["query-data-list"])
			);
			let new_list = Object.assign(query_data_list, query_data);
			// console.log("new_list", new_list);
			store.commit("panel-group/UPDATE_QUERY_DATA_LIST", new_list);
		};

		/**
		 * 处理导出数据的头部
		 * @param fields
		 * @returns {{}}
		 */
		const exportExcelHeaders = (fields) => {
			let headers = {};
			fields.map((item) => {
				headers[item] = item;
			});
			return headers;
		};
		onMounted(() => {
			fetchQueryList();
		});
		return {
			...toRefs(event),
			query_modal_show,
			exportExcelHeaders,
			fetchQuery,
			fetchQueryList,
			fetchQueryData,
		};
	},
};
</script>

<style scoped></style>
