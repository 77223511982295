<template>
	<b-card
		:style="
			$store.state.appConfig.layout.navbar.type === 'hidden' || y > 20
				? 'top: 3.5rem; right: 2rem'
				: 'top: 6rem; right: 2rem'
		"
		class="settings-topbar position-fixed d-inline-flex rounded p-1 shadow"
		no-body
		style="z-index: 100"
	>
		<!--全局时间配置-->
		<div class="d-inline-flex">
			<b-form-checkbox
				v-model="group_topbar.daterange.active"
				style="margin: 0.35rem 0.5rem"
			>
				全局时间区间：</b-form-checkbox
			>
			<div
				v-if="group_topbar.daterange.active"
				class="h-100"
				style="width: 12rem"
			>
				<div
					v-if="group_topbar.daterange.type === 1"
					class="h-100 w-100"
				>
					<div
						class="h-50 w-100 text-primary"
						style="line-height: 15px; font-size: 0.8rem"
					>
						起：{{ group_topbar.daterange.start }}
					</div>
					<div
						class="h-50 w-100 text-primary"
						style="line-height: 15px; font-size: 0.8rem"
					>
						止：{{ group_topbar.daterange.end }}
					</div>
				</div>
				<div
					v-else-if="group_topbar.daterange.type === 2"
					class="h-100 w-100 text-primary"
					style="line-height: 30px"
				>
					最近 {{ group_topbar.daterange.length }}
					{{ group_topbar.daterange.unit }}
				</div>
			</div>
			<div v-else class="h-100" style="width: 12rem; line-height: 30px">
				未启用
			</div>
			<b-modal
				id="modal-global-daterange"
				v-model="group_topbar.daterange.modal"
				cancel-title="取消"
				cancel-variant="outline-secondary"
				centered
				ok-title="确定"
				button-size="sm"
				title="设定全局时间区间"
			>
				<b-form>
					<b-form-group>
						<label>区间类型</label>
						<v-select
							id="time_query_type"
							v-model="group_topbar.daterange.type"
							:class="
								!group_topbar.daterange.type
									? 'border-danger'
									: ''
							"
							:options="[
								{ text: '精确区间', value: 1 },
								{ text: '模糊区间', value: 2 },
							]"
							:reduce="(text) => text.value"
							class="rounded"
							label="text"
						/>
					</b-form-group>
					<b-form-group>
						<label>时间区间</label><br />
						<template v-if="group_topbar.daterange.type === 1">
							<b-form-input
								v-model="group_topbar.daterange.start"
								class="d-inline-block"
								placeholder="2020-01-01 00:00:00"
								style="width: 45%"
							/>
							<div
								class="d-inline-block text-center"
								style="width: 10%"
							>
								~
							</div>
							<b-form-input
								v-model="group_topbar.daterange.end"
								class="d-inline-block"
								placeholder="2020-01-31 23:59:59"
								style="width: 45%"
							/>
						</template>
						<template v-else>
							<div class="d-inline-block" style="width: 20%">
								<label class="ma-0">最近</label>
							</div>
							<b-form-input
								v-model="group_topbar.daterange.length"
								class="d-inline-block"
								placeholder="3"
								style="width: 45%; margin-right: 5%"
								type="number"
							/>
							<b-form-select
								v-model="group_topbar.daterange.unit"
								:options="time_query_unit_list"
								class="d-inline-block"
								style="width: 30%"
							/>
						</template>
					</b-form-group>
				</b-form>
			</b-modal>

			<!--顶部按钮组-->
			<b-button
				v-b-tooltip.hover
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				class="btn-icon settings-topbar-btns"
				size="sm"
				title="刷新"
				variant="info"
			>
				<feather-icon icon="RefreshCcwIcon" />
			</b-button>
			<b-button
				v-b-tooltip.hover
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				class="btn-icon settings-topbar-btns"
				size="sm"
				title=""
				variant="danger"
			>
				<feather-icon icon="LockIcon" />
			</b-button>
			<b-button
				v-b-tooltip.hover
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				:variant="
					$store.state.appConfig.layout.menu.hidden
						? 'primary'
						: 'secondary'
				"
				class="btn-icon settings-topbar-btns"
				size="sm"
				title=""
				@click="triggerNavbarHidden()"
			>
				<feather-icon icon="AirplayIcon" />
			</b-button>
			<b-button
				v-b-modal.share-modal
				v-b-tooltip.hover
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				class="btn-icon settings-topbar-btns"
				size="sm"
				title=""
				variant="warning"
			>
				<feather-icon icon="Share2Icon" />
			</b-button>
			<share-modal
				:key="'panel-topbar-share-modal' + $route.params.uid"
			></share-modal>
			<b-button
				v-b-tooltip.hover
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				class="btn-icon settings-topbar-btns"
				size="sm"
				title="添加"
				variant="success"
				@click="group_sidebar_show"
			>
				<feather-icon icon="PlusIcon" />
			</b-button>
		</div>
	</b-card>
</template>

<script>
import useGroupTopbar from "./GroupTopbarUse";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ShareModal from "../ShareModal/ShareModal";

export default {
	name: "GroupTopbar",
	directives: {
		Ripple,
	},
	components: {
		vSelect,
		ShareModal,
	},
	setup() {
		const {
			y,
			group_topbar,
			time_query_unit_list,
			isVerticalMenuHidden,
			group_sidebar_show,
			triggerNavbarHidden,
		} = useGroupTopbar();
		return {
			y,
			group_topbar,
			time_query_unit_list,
			isVerticalMenuHidden,
			group_sidebar_show,
			triggerNavbarHidden,
		};
	},
};
</script>

<style scoped>
.settings-topbar-btns {
	margin-left: 0.5rem;
}
</style>
