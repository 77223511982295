import { reactive, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import { group_topbar, time_query_unit_list } from "@/views/panel-group/defaultSettings";
import useAppConfig from "@/@core/app-config/useAppConfig";
import { useWindowScroll } from "@vueuse/core";

export default function groupTopbar() {
	const { isVerticalMenuHidden } = useAppConfig();
	const { y } = useWindowScroll();
	const event = reactive({
		time_query_unit_list: time_query_unit_list,
		group_topbar: group_topbar,
		isVerticalMenuHidden: isVerticalMenuHidden,
		y: y,
	});
	watch(
		() => event.group_topbar["daterange"].active,
		(value) => {
			if (value) {
				event.group_topbar["daterange"].modal = true;
			}
		}
	);

	//显示侧边工具库
	const group_sidebar_show = () => {
		store.commit("panel-group/UPDATE_GROUP_SIDEBAR_SHOW", true);
	};
	//切换展示模式（去除menu和topbar）
	const triggerNavbarHidden = () => {
		let nav_type = store.state.appConfig.layout.navbar.type;
		nav_type = nav_type === "hidden" ? "static" : "hidden";
		store.commit("appConfig/UPDATE_NAVBAR_TYPE", nav_type);
		// store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", menu_hidden);
		// store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", !menu_hidden);
	};

	return {
		...toRefs(event),
		group_sidebar_show,
		triggerNavbarHidden,
	};
}
