<template>
	<section>
		<page-title v-if="show_title"></page-title>
		<!------------------------------------------------
-顶部功能区：
-全局时间区间、及其他功能按钮
------------------------------------------------->
		<group-topbar
			ref="group-topbar"
			:group-uid="'group-topbar-' + group_uid"
		></group-topbar>

		<!------------------------------------------------
-组件展示区：
-使用vue-grid-layout实现组件的拖拽改变大小和排序
------------------------------------------------->
		<div class="position-absolute w-100">
			<div
				v-if="loading"
				class="d-flex justify-content-center mb-1"
				style="margin-top: 15%"
			>
				<b-spinner
					label="Loading..."
					style="width: 3rem; height: 3rem"
					variant="primary"
				/>
			</div>
			<h5
				v-else-if="!panel_group.panel_layout_list.length"
				class="text-center"
				style="margin-top: 15%"
			>
				{{ $t("No Data") }}
			</h5>
		</div>

		<div
			v-show="!loading"
			id="panel-group-content"
			class="w-100"
			style="margin-left: -25px; margin-top: 3px"
		>
			<grid-layout
				ref="gridlayout"
				:col-num="grid_layout.colNum"
				:is-draggable="true"
				:is-resizable="true"
				:layout.sync="panel_group.panel_layout_list"
				:row-height="grid_layout.rowHeight"
				:use-css-transforms="true"
				:vertical-compact="true"
				style="min-height: 30rem; width: calc(100% + 35px)"
				@layout-updated="updateLayout"
			>
				<template
					v-for="(item, itemIndex) in panel_group.panel_layout_list"
				>
					<grid-item
						v-if="!!id2item(item.panel_id)"
						:key="'panel_' + item.panel_id"
						:h="item.h"
						:i="item.i"
						:min-h="5"
						:min-w="4"
						:w="item.w"
						:x="item.x"
						:y="item.y"
						drag-allow-from=".vue-draggable-handle"
						drag-ignore-from=".no-drag"
						style="margin: -1rem 0"
					>
						<b-card
							v-if="item.i !== 'drop'"
							class="panel-item position-relative shadow"
							style="
								margin: 15px;
								width: calc(100% - 15px);
								height: calc(100% - 15px);
							"
							@mouseleave="item.overlay = false"
						>
							<b-card-title
								v-if="id2item(item.panel_id).panel_name !== ''"
								:show="!!id2item(item.panel_id).panel_name"
								class="text-center"
							>
								{{ id2item(item.panel_id).panel_name }}
							</b-card-title>
							<b-card-text
								:style="
									id2item(item.panel_id).panel_name === ''
										? 'height: 100%'
										: 'height: calc(100% - 3rem)'
								"
								class="w-100"
							>
								<b-button
									v-b-tooltip.hover
									v-ripple.400="'rgba(255,255,255, 0.15)'"
									class="
										vue-draggable-handle
										position-absolute
										btn-icon
									"
									style="
										top: 0;
										right: 0;
										z-index: 11;
										padding: 0.25rem;
									"
									title="拖动"
									variant="flat-warning"
									@mouseover="item.overlay = true"
								>
									<feather-icon
										:icon="
											(item.overlay
												? 'Move'
												: 'MoreHorizontal') + 'Icon'
										"
										size="16"
									/>
								</b-button>
								<b-overlay
									:blur="'3rem'"
									:opacity="0.9"
									:show="item.overlay"
									:variant="'transparent'"
									class="
										vue-draggable-handle
										position-absolute
										h-100
										w-100
									"
									style="top: 0; left: 0"
								>
									<template v-slot:overlay>
										<div
											ref="dialog"
											aria-modal="false"
											class="text-center"
											role="dialog"
											tabindex="-1"
										>
											<div>
												组件ID：{{ item.panel_id }}
											</div>
											<div>
												组件名称：{{
													id2item(item.panel_id)
														.panel_name
														? id2item(item.panel_id)
																.panel_name
														: "暂无"
												}}
											</div>
											<div class="mb-1">
												组件类型：{{ item.type }}
											</div>

											<div>
												<!--配置组件-->
												<b-button
													v-b-modal.panel-modal
													v-b-tooltip.hover
													v-ripple.400="
														'rgba(255,255,255, 0.15)'
													"
													style="
														padding: 0.5rem;
														margin: 0.25rem;
													"
													title="配置"
													variant="success"
													@click="
														panel_modal_show(
															item.panel_id
														)
													"
												>
													<feather-icon
														icon="GridIcon"
														size="16"
													/>
												</b-button>
												<!--复制组件-->
												<b-button
													v-b-tooltip.hover
													v-ripple.400="
														'rgba(255,255,255, 0.15)'
													"
													style="
														padding: 0.5rem;
														margin: 0.25rem;
													"
													title="复制"
													variant="warning"
													@click="
														copy_panel(itemIndex)
													"
												>
													<feather-icon
														icon="CopyIcon"
														size="16"
													/>
												</b-button>
												<!--删除组件-->
												<b-button
													v-b-tooltip.hover
													v-ripple.400="
														'rgba(255,255,255, 0.15)'
													"
													style="
														padding: 0.5rem;
														margin: 0.25rem;
													"
													title="删除"
													variant="danger"
													@click="
														del_panel(itemIndex)
													"
												>
													<feather-icon
														icon="XIcon"
														size="16"
													/>
												</b-button>
											</div>
										</div>
									</template>
								</b-overlay>
								<!-- g2 -->
								<div
									:id="'panel-' + item.panel_id"
									class="
										scroll-area
										rounded
										overflow-hidden
										w-100
										h-100
									"
								>
									<component
										:is="
											'panel-component-' +
											item.type.toLowerCase()
										"
										:key="'panel-' + item.panel_id"
										:ref="'panel_' + item.panel_id"
										:item="id2item(item.panel_id)"
										class="no-drag zindex-0 w-100 h-100"
									></component>
								</div>
							</b-card-text>
						</b-card>
					</grid-item>
				</template>
			</grid-layout>
		</div>
		<!------------------------------------------------
组件配置弹窗：
用于配置组件的数据和样式等参数
------------------------------------------------->
		<panel-modal @panel-modal-submit="fetchPanelGroup"></panel-modal>
		<!------------------------------------------------
组件库侧边栏：
用于拖动组件
------------------------------------------------->
		<b-sidebar
			id="group-sidebar"
			v-model="$store.state['panel-group'].sidebar.show"
			backdrop
			bg-variant="white"
			right
			shadow
			sidebar-class="sidebar-lg"
		>
			<h3 class="font-title text-center mb-2">组件库</h3>
			<div
				class="d-flex flex-wrap justify-content-start w-100 px-2"
				style="break-inside: avoid"
			>
				<div
					v-for="(item, itemIndex) in panel_type_list"
					:key="'panel_type_' + item.value"
					:style="itemIndex % 2 === 1 ? '' : 'margin-right: 6px'"
					class="d-flex flex-column"
					style="width: calc((100% / 2) - 3px); padding-bottom: 6px"
				>
					<b-button
						v-b-tooltip.hover
						:title="'拖动至编辑区新建' + item.text"
						class="
							bg-light-secondary
							w-100
							p-1
							text-left
							d-flex
							flex-column
							justify-content-between
						"
						draggable="true"
						style="height: 6rem; cursor: move"
						unselectable="on"
						variant="flat-primary"
						@drag="drag(item.value, $event)"
						@dragend="dragend(item.value, $event)"
					>
						<feather-icon
							:icon="item.icon || 'EditIcon'"
							size="18"
						/>

						<div class="w-100 text-nowrap text-truncate">
							{{ item.text }}
						</div>
					</b-button>
				</div>
			</div>
		</b-sidebar>
	</section>
</template>

<script>
import store from "@/store";
import { GridItem, GridLayout } from "vue-grid-layout";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { showToast } from "@/libs/utils/showToast";
import {
	computed,
	getCurrentInstance,
	onMounted,
	reactive,
	toRefs,
	watch,
} from "@vue/composition-api";
import {
	panel_default,
	panel_group_default,
	panel_type_list,
} from "@/views/panel-group/defaultSettings";
import PanelModal from "../PanelModal/PanelModal";
import GroupTopbar from "../GroupTopbar/GroupTopbar";
import PageTitle from "@/views/layout/PageTitle";
import { showConfirm } from "@/libs/utils/showConfirm";
import { routerParams } from "@/libs/utils/routerParams";

let mouseXY = {
	x: null,
	y: null,
};
let DragPos = {
	x: null,
	y: null,
	w: 4,
	h: 4,
	i: null,
	panel_id: null,
	static: false,
	overlay: false,
	type: "blank",
};
let drag_time = 0;
document.addEventListener(
	"dragover",
	function (e) {
		mouseXY.x = e.clientX - 50;
		mouseXY.y = e.clientY - 50;
	},
	false
);

export default {
	name: "PanelGroup",
	directives: {
		Ripple: () => import("vue-ripple-directive"),
	},
	components: {
		PageTitle,
		GridLayout,
		GridItem,
		VuePerfectScrollbar,
		//引入组件配置弹窗
		PanelModal,
		//引入顶部全局配置栏
		GroupTopbar,
	},
	props: ["group-uid", "show-title"],
	setup(props) {
		const instance = getCurrentInstance();
		const dom_refs = instance.refs;
		let event = reactive({
			loading: false,
			grid_layout: {
				colNum: 24,
				rowHeight: 30,
			},
			route: computed(() => {
				return instance.proxy.$route;
			}),
			group_uid: computed(() => {
				return props.groupUid;
			}),
			show_title: computed(() => {
				return props.showTitle;
			}),
			panel_group: Object.assign({}, panel_group_default),
			panel_type_list: panel_type_list,
			query_data_list: computed(() => {
				return store.state["panel-group"]["query-data-list"];
			}),
			project_id: computed(() => {
				return routerParams("project_id");
			}),
			drop_index: 0,
		});
		watch(
			() => event.group_uid,
			(uid) => {
				if (uid) {
					console.log("group-uid", uid);
					fetchPanelGroup();
				}
			}
		);
		onMounted(() => {
			fetchPanelGroup();
		});
		const sort_layout = (a, b) => {
			return parseInt(b.i) - parseInt(a.i);
		};
		//更新当前layout列表
		const updateLayout = (panel_layout_list = null) => {
			const panel_layout_list_new = JSON.stringify(panel_layout_list);
			const panel_layout_list_old = JSON.stringify(
				event.panel_group.panel_layout_list
			);
			panel_layout_list = panel_layout_list
				? JSON.parse(panel_layout_list_new)
				: JSON.parse(panel_layout_list_old);
			panel_layout_list.map((item, index) => {
				delete item.static;
				delete item.overlay;
				delete item.moved;
				panel_layout_list[index]["i"] = String(index);
			});
			const group_id = event.panel_group.group_id;
			if (group_id) {
				const payload = {
					project_id: event.project_id,
					group_id: group_id,
					panel_layout_list: panel_layout_list,
				};
				store
					.dispatch("panel-group/updatePanelGroup", payload)
					.then((response) => {})
					.catch((error) => {
						console.log("组件更新错误：", error);
						showToast(error);
					});
			} else {
			}
		};
		//获取当前panel-group数据
		const fetchPanelGroup = () => {
			//获取当前router的参数（uid）
			const panel_group_uid = event.group_uid;
			if (panel_group_uid) {
				//获取当前panel-group的所有数据
				event.loading = true;
				event.panel_group = Object.assign({}, panel_group_default);
				store
					.dispatch("panel-group/fetchPanelGroup", {
						project_id: event.project_id,
						panel_group_uid: panel_group_uid,
					})
					.then((response) => {
						//处理数据
						response.data.panel_layout_list.map(
							(item) =>
								(item = Object.assign(item, {
									static: false,
									overlay: false,
								}))
						);
						event.panel_group = response.data;
						event.panel_group.panel_list = response.data.panel_list;
						event.panel_group.panel_layout_list =
							response.data.panel_layout_list;
						event.loading = false;
					})
					.then(() => {
						getQueryList();
					})
					.catch((error) => {
						event.loading = false;
						showToast(error);
					});
			}
		};
		//获取页面已有查询id的数据
		const getQueryList = () => {
			//获取所有的query_id(去除null、重复值、store中已经存在的query_id)
			const store_query_id_list = Object.keys(event.query_data_list);
			let query_id_list = Array.from(
				new Set(
					event.panel_group.panel_list
						.map((item) => item.query_id)
						.filter(
							(query_id) =>
								!!query_id &&
								!store_query_id_list.includes(query_id)
						)
				)
			);
			//逐个查询数据
			if (query_id_list.length > 0) {
				query_id_list.map((query_id) => {
					setTimeout(() => {
						store
							.dispatch("panel-group/fetchQueryData", {
								project_id: event.project_id,
								query_id: query_id,
							})
							.then((res) => {
								//合并store中原有数据和新数据，并更新store
								update_query_data_list(query_id, res.data);
							})
							.catch((error) => {
								showToast(error);
							});
					}, 20);
				});
			}
		};
		//合并store中原有数据和新数据，并更新store
		const update_query_data_list = (query_id, data) => {
			let query_data = {};
			query_data["query_" + query_id] = data;
			let query_data_list = JSON.parse(
				JSON.stringify(store.state["panel-group"]["query-data-list"])
			);
			let new_list = Object.assign(query_data_list, query_data);
			store.commit("panel-group/UPDATE_QUERY_DATA_LIST", new_list);
		};
		//显示panel_modal
		const panel_modal_show = (panel_id) => {
			store.commit("panel-group/UPDATE_PANEL_MODAL", id2item(panel_id));
		};
		//根据panel_id获取panel参数
		const id2item = (id) => {
			let panel = null;
			let panel_list = event.panel_group.panel_list;
			for (let item of panel_list) {
				if (parseInt(id) === parseInt(item.panel_id)) {
					panel = item;
					break;
				}
			}
			return panel;
		};

		//拖动事件
		const drag = (type, e) => {
			if (drag_time < 100) {
				drag_time += 1;
				return;
			} else {
				drag_time = 0;
			}
			console.log("drag:start");
			let parentRect = document
				.getElementById("panel-group-content")
				.getBoundingClientRect();
			let mouseInGrid = false;
			if (
				mouseXY.x > parentRect.left &&
				mouseXY.x < parentRect.right &&
				mouseXY.y > parentRect.top &&
				mouseXY.y < parentRect.bottom
			) {
				mouseInGrid = true;
			}
			if (
				mouseInGrid === true &&
				event.panel_group.panel_layout_list.findIndex(
					(item) => item.i === "drop"
				) === -1
			) {
				const layout_list = event.panel_group.panel_layout_list;
				event.panel_group.panel_layout_list.push({
					x:
						(layout_list.length * 2) %
						(event.grid_layout.colNum || 24),
					y: layout_list.length + (event.grid_layout.colNum || 24), // puts it at the bottom
					w: 4,
					h: 4,
					i: "drop",
					panel_id: null,
					static: false,
					overlay: false,
					type: type,
				});
				if (store.state["panel-group"]["sidebar"].show) {
					store.commit(
						"panel-group/UPDATE_GROUP_SIDEBAR_SHOW",
						false
					);
				}
			}
			event.drop_index = event.panel_group.panel_layout_list.findIndex(
				(item) => item.i === "drop"
			);
			let index = event.drop_index;
			if (index !== -1) {
				try {
					dom_refs.gridlayout.$children[
						event.panel_group.panel_layout_list.length
					].$refs.item.style.display = "none";
				} catch {}

				let el = dom_refs.gridlayout.$children[index];
				el.dragging = {
					top: mouseXY.y - parentRect.top,
					left: mouseXY.x - parentRect.left,
				};
				let new_pos = el.calcXY(
					mouseXY.y - parentRect.top,
					mouseXY.x - parentRect.left
				);
				if (mouseInGrid === true) {
					dom_refs.gridlayout.dragEvent(
						"dragstart",
						"drop",
						new_pos.x,
						new_pos.y,
						4,
						4
					);
					DragPos.i = String(index + 1);
					DragPos.x = event.panel_group.panel_layout_list[index].x;
					DragPos.y = event.panel_group.panel_layout_list[index].y;
				}
				if (mouseInGrid === false) {
					dom_refs.gridlayout.dragEvent(
						"dragend",
						"drop",
						new_pos.x,
						new_pos.y,
						4,
						4
					);
					event.panel_group.panel_layout_list =
						event.panel_group.panel_layout_list.filter(
							(obj) => obj.i !== "drop"
						);
				}
			}
		};
		//拖动结束事件
		const dragend = (type, e) => {
			console.log("drag:end");
			let parentRect = document
				.getElementById("panel-group-content")
				.getBoundingClientRect();
			let mouseInGrid = false;
			if (
				mouseXY.x > parentRect.left &&
				mouseXY.x < parentRect.right &&
				mouseXY.y > parentRect.top &&
				mouseXY.y < parentRect.bottom
			) {
				mouseInGrid = true;
			}
			if (mouseInGrid === true) {
				dom_refs.gridlayout.dragEvent(
					"dragend",
					"drop",
					DragPos.x,
					DragPos.y,
					4,
					4
				);

				event.panel_group.panel_layout_list =
					event.panel_group.panel_layout_list.filter(
						(obj) => obj.i !== "drop"
					);

				// UNCOMMENT below if you want to add a grid-item
				new_panel(type);
				dom_refs.gridlayout.dragEvent(
					"dragend",
					DragPos.i,
					DragPos.x,
					DragPos.y,
					4,
					4
				);
				dom_refs.gridlayout.$children[
					event.panel_group.panel_layout_list.length
				].$refs.item.style.display = "block";
			}
		};
		//向layout和list中添加新panel数据
		const new_panel = (
			type = "g2-mix",
			panel_layout = null,
			panel_params = null
		) => {
			//生成新panel
			let panel_id = null;
			let new_panel_options;
			if (!panel_params) {
				new_panel_options = Object.assign(panel_default, {
					panel_id: null,
					panel_name:
						"新的 " +
						(type === "g2-mix" ? "chart" : type) +
						" 组件",
					type: type,
					options_type: type === "g2-mix" ? 2 : 3,
					options: panel_type_list.find(
						(option) => type === option.value
					).options,
				});
			} else {
				new_panel_options = panel_params;
				new_panel_options.panel_id = null;
			}
			store
				.dispatch("panel-group/addPanelItem", new_panel_options)
				.then((response) => {
					panel_id = response.data.panel_id;
					event.panel_group.panel_list.push(response.data);
					if (!panel_layout) {
						panel_layout = {
							x: DragPos.x || 0,
							y: DragPos.y || 0,
							w: 4,
							h: 4,
							i: String(event.drop_index + 1),
							panel_id: panel_id,
							static: false,
							overlay: false,
							type: type,
						};
					} else {
						const layout_list = event.panel_group.panel_layout_list;
						panel_layout.panel_id = panel_id;
						panel_layout.x =
							(layout_list.length * 4) %
							(event.grid_layout.colNum || 24);
						panel_layout.y =
							layout_list.length +
							(event.grid_layout.colNum || 24);
						panel_layout.i = String(layout_list.length);
					}
					event.panel_group.panel_layout_list.push(panel_layout);
					event.panel_group.panel_layout_list.map((item, index) => {
						event.panel_group.panel_layout_list[index]["i"] =
							String(index);
					});
					console.log(
						"dragend:",
						event.panel_group.panel_layout_list
					);
				})
				.catch((error) => {
					showToast(error);
				});
		};
		//删除组件
		const del_panel = (index) => {
			showConfirm("确认删除?", () => {
				event.panel_group.panel_layout_list.splice(index, 1);
				// updateLayout();
			});
		};
		//复制组件
		const copy_panel = (index) => {
			showConfirm("确认复制此组件?", () => {
				const panel_layout = JSON.parse(
					JSON.stringify(event.panel_group.panel_layout_list[index])
				);
				const type = panel_layout.type;
				const panel_params = JSON.parse(
					JSON.stringify(id2item(panel_layout.panel_id))
				);
				new_panel(type, panel_layout, panel_params);
			});
		};
		return {
			...toRefs(event),
			panel_modal_show,
			id2item,
			drag,
			dragend,
			fetchPanelGroup,
			del_panel,
			copy_panel,
			updateLayout,
		};
	},
};
</script>
<style>
.dark-layout .swal2-popup {
	background: #283046;
}

.dark-layout .swal2-content {
	color: #d0d2d6;
}

.droppable-element {
	background: #fdd;
	border: 1px solid black;
	margin: 10px 0;
	padding: 10px;
	text-align: center;
	width: 150px;
}

.vue-grid-item > .vue-resizable-handle {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKEAYAAADdohP+AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAAASAAAAEgARslrPgAAAVVJREFUKM9jYCAR/P8//7/9fw6OLzGzdJ1ebNjwNX3mTGexiRNh8kykGvT1/K+HLGrr1zOo/M9h+O7q+s/kv87/X2vXEu+if6tWhoawsX3pm8nmbL5585eGmTOd7n/9+iVmtprjByenATAIFkbnZj5wVt2+HWbQ5//T/zv/d3BAV//Gd26ntTAvL4zPiDOMNjHs/L/Lzu5//b80RgVvb17GTMa9jAcOwNR/nj3jqJOAnR3TbUZOxpitWxnfM5xmOBYby0KuQYw3GTMYRbdt+9/B+IOB5dgxLuYvwl837tzJ8GXezJlO17dsIeQ1mEFfSmbqOqt++fLlzyxV54Jdu/7/77OweMzJCU82jEkMAYwb37//78d4gdnUywvDRefmVXqqiIrCvMbQwfiDwfvYMW7mL8Jfi/39GRmLTpyQ/f6dhEhatSo0lJn5y59Zqk4CsbHoLkIHACoj4fDa6MEdAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA2LTAxVDIwOjA4OjMxKzA4OjAwN/U4UQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNi0wMVQyMDowODozMSswODowMEaogO0AAABJdEVYdHN2ZzpiYXNlLXVyaQBmaWxlOi8vL2hvbWUvYWRtaW4vaWNvbi1mb250L3RtcC9pY29uX2YwdDc5YXMzOGFyL3Jlc2l6ZS5zdmdCr+VHAAAAAElFTkSuQmCC");
	z-index: 11;
}

.global-settings label,
.global-settings .col-form-label {
	line-height: 1.5rem;
	margin: 0;
	text-align: center;
}

.global-settings .form-group {
	margin: 0;
}

.b-overlay > div {
	border-radius: 0.357rem !important;
}

.form-group {
	margin-bottom: 0.5rem;
}

.col-form-label {
	margin: 0;
}
</style>
